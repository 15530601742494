:root {
  --amplify-colors-primary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-primary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-primary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-primary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-primary-50: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-primary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-primary-90: var(--amplify-colors-neutral-80);
  --amplify-colors-font-interactive: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-black-90);
  --amplify-components-tabs-item-focus-color: var(--amplify-colors-black-60);
  --amplify-components-tabs-item-hover-color: var(--amplify-colors-black-10);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-90);
  --amplify-colors-font-focus: var(--amplify-colors-neutral-40);
  --amplify-components-button-hover-color: var(--amplify-colors-neutral-40);
  --amplify-components-button-hover-background-color: var(--amplify-colors-neutral-40);
}

.amplify-button--primary {
  --amplify-internal-button-background-color: var(--amplify-colors-neutral-100);
}
