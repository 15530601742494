body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@keyframes fadeIn {
    0% {
        /* transform: translateY(100%); */
        opacity: 0;
    }

    100% {
        /* transform: translateY(0%); */
        opacity: 1;
    }
}

.fadeIn-animation {
    animation: 1.5s fadeIn;
}